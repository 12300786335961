import React from "react";
import Helmet from "react-helmet";
import Layout from "../components/layout";

function Contact() {
  const [submissionStatus, setSubmissionStatus] = React.useState("");
  // Sends form data to formspree.io
  async function handleSubmit(e) {
    e.preventDefault();
    const form = e.target;
    const response = await fetch("https://formspree.io/f/xyyvnvaz", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: new FormData(form),
    });
    form.reset();
    // set submission status
    if (response.status == 200) {
      setSubmissionStatus("Thanks for reaching out!");
    }
    else {
      setSubmissionStatus("Oops! Something went wrong. Please try again.");
    }
  };

  const siteTitle = "Kiwok - Contact";
  return (
    <Layout>
      <Helmet title={siteTitle} />
      <div className="containerWrapper">
        <div className="wrapperPage">
          <h1 className="PageH1">Contact us</h1>
          <p className="contactText">
            We will help you turn your ideas to reality. Send us a message and we
            will reply to you shortly.
          </p>
          <div className="contactForm">
            <form name="contact" onSubmit={handleSubmit}>
              <input name="name" placeholder="Name" type="text" />
              <input name="organization" placeholder="Organization" type="text" />
              <input name="email" placeholder="Email address" type="email" />
              <textarea name="message" placeholder="Message..."></textarea>
              <p className="submissionStatus">{submissionStatus}</p>
              <button className="contactButton" type="submit">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Contact;
